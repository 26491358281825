// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as Col from "../../../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Grid from "../../../../../styleguide/components/Grid/Grid.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../../../bindings/Quill.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Editor from "../../../../../styleguide/forms/Editor/Editor.res.js";
import * as Select from "../../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ResourceDocument from "../../../../resources/resource-new/ResourceDocument.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderBrochure from "../../../../../api/provider-brochures/Api_ProviderBrochure.res.js";
import * as IconAdditionalUpload from "../../../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Routes_ProviderBrochure from "../../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as ProviderBrochureNewFormScss from "./ProviderBrochureNewForm.scss";

var css = ProviderBrochureNewFormScss;

var validators_title = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.title.length;
      if (length !== 0) {
        if (length < 20) {
          return {
                  TAG: "Error",
                  _0: "Title must be more than 20 characters."
                };
        } else if (length > 100) {
          return {
                  TAG: "Error",
                  _0: "Title must be no more than 100 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.title
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Title is required."
              };
      }
    })
};

var validators_subtitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.subtitle.length;
      if (length !== 0) {
        if (length < 50) {
          return {
                  TAG: "Error",
                  _0: "Summary must be more than 50 characters."
                };
        } else if (length > 200) {
          return {
                  TAG: "Error",
                  _0: "Summary must be no more than 200 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.subtitle
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Subtitle is required"
              };
      }
    })
};

var validators_metaTitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaTitle.length;
      if (length > 200) {
        return {
                TAG: "Error",
                _0: "Meta title must no more than 200 characters."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.metaTitle
              };
      }
    })
};

var validators_metaDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaDescription.length;
      if (length > 500) {
        return {
                TAG: "Error",
                _0: "Meta description must no more than 500 characters."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.metaDescription
              };
      }
    })
};

var validators_active = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.active;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Active is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.active
              };
      }
    })
};

var validators = {
  title: validators_title,
  subtitle: validators_subtitle,
  metaTitle: validators_metaTitle,
  metaDescription: validators_metaDescription,
  active: validators_active,
  providerId: undefined
};

function initialFieldsStatuses(_input) {
  return {
          title: "Pristine",
          subtitle: "Pristine",
          metaTitle: "Pristine",
          metaDescription: "Pristine",
          active: "Pristine",
          providerId: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.title;
  var tmp;
  tmp = typeof match !== "object" ? validators.title.validate(input) : match._0;
  var match$1 = fieldsStatuses.subtitle;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.subtitle.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.metaTitle;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.metaTitle.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.metaDescription;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.metaDescription.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.active;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.active.validate(input) : match$4._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.providerId
  };
  var titleResult = tmp;
  var titleResult$1;
  if (titleResult.TAG === "Ok") {
    var subtitleResult = tmp$1;
    if (subtitleResult.TAG === "Ok") {
      var metaTitleResult = tmp$2;
      if (metaTitleResult.TAG === "Ok") {
        var metaDescriptionResult = tmp$3;
        if (metaDescriptionResult.TAG === "Ok") {
          var activeResult = tmp$4;
          if (activeResult.TAG === "Ok") {
            var providerIdResult = match_0;
            if (providerIdResult.TAG === "Ok") {
              return {
                      TAG: "Valid",
                      output: {
                        title: titleResult._0,
                        subtitle: subtitleResult._0,
                        metaTitle: metaTitleResult._0,
                        metaDescription: metaDescriptionResult._0,
                        active: activeResult._0,
                        providerId: providerIdResult._0
                      },
                      fieldsStatuses: {
                        title: {
                          TAG: "Dirty",
                          _0: titleResult,
                          _1: "Shown"
                        },
                        subtitle: {
                          TAG: "Dirty",
                          _0: subtitleResult,
                          _1: "Shown"
                        },
                        metaTitle: {
                          TAG: "Dirty",
                          _0: metaTitleResult,
                          _1: "Shown"
                        },
                        metaDescription: {
                          TAG: "Dirty",
                          _0: metaDescriptionResult,
                          _1: "Shown"
                        },
                        active: {
                          TAG: "Dirty",
                          _0: activeResult,
                          _1: "Shown"
                        },
                        providerId: {
                          TAG: "Dirty",
                          _0: providerIdResult,
                          _1: "Hidden"
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            titleResult$1 = titleResult;
          } else {
            titleResult$1 = titleResult;
          }
        } else {
          titleResult$1 = titleResult;
        }
      } else {
        titleResult$1 = titleResult;
      }
    } else {
      titleResult$1 = titleResult;
    }
  } else {
    titleResult$1 = titleResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            title: {
              TAG: "Dirty",
              _0: titleResult$1,
              _1: "Shown"
            },
            subtitle: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            metaTitle: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            active: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            },
            providerId: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurTitleField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: status,
                                  subtitle: init.subtitle,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription,
                                  active: init.active,
                                  providerId: init.providerId
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurSubtitleField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.subtitle, validators_subtitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  subtitle: status,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription,
                                  active: init.active,
                                  providerId: init.providerId
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaTitleField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaTitle, validators_metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  subtitle: init.subtitle,
                                  metaTitle: status,
                                  metaDescription: init.metaDescription,
                                  active: init.active,
                                  providerId: init.providerId
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaDescription, validators_metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  subtitle: init.subtitle,
                                  metaTitle: init.metaTitle,
                                  metaDescription: status,
                                  active: init.active,
                                  providerId: init.providerId
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.active, validators_active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  subtitle: init.subtitle,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription,
                                  active: status,
                                  providerId: init.providerId
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurProviderIdField" :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.providerId, state.fieldsStatuses.providerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  subtitle: init.subtitle,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription,
                                  active: init.active,
                                  providerId: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateTitleField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: status,
                                            subtitle: init.subtitle,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription,
                                            active: init.active,
                                            providerId: init.providerId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateSubtitleField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.subtitle, state.submissionStatus, validators_subtitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: init.title,
                                            subtitle: status,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription,
                                            active: init.active,
                                            providerId: init.providerId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaTitleField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.metaTitle, state.submissionStatus, validators_metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: init.title,
                                            subtitle: init.subtitle,
                                            metaTitle: status,
                                            metaDescription: init.metaDescription,
                                            active: init.active,
                                            providerId: init.providerId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.metaDescription, state.submissionStatus, validators_metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: init.title,
                                            subtitle: init.subtitle,
                                            metaTitle: init.metaTitle,
                                            metaDescription: status,
                                            active: init.active,
                                            providerId: init.providerId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.active, state.submissionStatus, validators_active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: init.title,
                                            subtitle: init.subtitle,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription,
                                            active: status,
                                            providerId: init.providerId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateProviderIdField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.providerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: init.title,
                                            subtitle: init.subtitle,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription,
                                            active: init.active,
                                            providerId: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateSubtitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateSubtitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateProviderId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProviderIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurTitle: (function () {
              dispatch("BlurTitleField");
            }),
          blurSubtitle: (function () {
              dispatch("BlurSubtitleField");
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          blurProviderId: (function () {
              dispatch("BlurProviderIdField");
            }),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          subtitleResult: Formality.exposeFieldResult(state.fieldsStatuses.subtitle),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          providerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.providerId),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.title;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.subtitle;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.metaTitle;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.metaDescription;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.active;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.providerId;
              if (typeof tmp$5 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ProviderBrochureForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderBrochureNewForm(props) {
  var providers = props.providers;
  var initialState_editorState = Editor.State.make();
  var initialState = {
    document: undefined,
    editorState: initialState_editorState
  };
  var initialInput = React.useMemo((function () {
          return {
                  title: "",
                  subtitle: "",
                  metaTitle: "",
                  metaDescription: "",
                  active: "False",
                  providerId: Belt_Option.getExn(Belt_Option.map(Belt_Array.get(providers, 0), (function (x) {
                              return x.id;
                            })))
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (action.TAG !== "SetDocument") {
            return {
                    TAG: "Update",
                    _0: {
                      document: state.document,
                      editorState: Editor.State.compose(state.editorState, action._0)
                    }
                  };
          }
          var $$document = action._0;
          console.log($$document);
          return {
                  TAG: "Update",
                  _0: {
                    document: $$document,
                    editorState: state.editorState
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var form = useForm(initialInput, (function (output, cb) {
          var match = output.active;
          var tmp;
          switch (match) {
            case "false" :
                tmp = false;
                break;
            case "true" :
                tmp = true;
                break;
            default:
              tmp = false;
          }
          $$Promise.wait(Api_ProviderBrochure.create(output.title, output.subtitle, document.querySelector(".ql-editor").innerHTML, output.metaTitle, output.metaDescription, Belt_Option.getExn(state.document), tmp, output.providerId), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_ProviderBrochure.Dashboard.edit(x._0.id));
                  } else {
                    return SentryLogger.error1({
                                rootModule: "ProviderBrochureNewForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderBrochureNewForm.make"
                              }, "ProviderBrochureCreate::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.resourceToolbarOptions, {}, undefined, container);
                    })));
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  var match$1 = form.subtitleResult;
  var tmp;
  tmp = match$1 !== undefined && match$1.TAG !== "Ok" ? JsxRuntime.jsx(ErrorMessage.make, {
          className: css.error,
          children: match$1._0
        }) : null;
  var match$2 = state.document;
  var match$3 = form.status;
  var tmp$1;
  tmp$1 = typeof match$3 !== "object" || match$3.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  className: css.buttonHiddenContainer,
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  className: css.buttonHiddenContainer,
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        className: css.error,
                        children: "Something went wrong."
                      })
                })
          ]
        });
  var match$4 = state.document;
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Grid.make, {
                    className: css.grid,
                    children: [
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--title",
                                          children: "Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "provider-brochure-form--title",
                                            value: form.input.title,
                                            placeholder: "Add a title for your new brochure.",
                                            onChange: (function ($$event) {
                                                form.updateTitle((function (input, value) {
                                                        return {
                                                                title: value,
                                                                subtitle: input.subtitle,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                active: input.active,
                                                                providerId: input.providerId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.titleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--resource-summary",
                                          children: "Subtitle"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "provider-brochure-form--resource-summary",
                                            value: form.input.subtitle,
                                            placeholder: "Add a subtitle for your resource.",
                                            onChange: (function ($$event) {
                                                form.updateSubtitle((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                subtitle: value,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                active: input.active,
                                                                providerId: input.providerId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      tmp
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--provider-brochure-description",
                                          children: "Description"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Editor.make, {
                                                ref: containerRef
                                              }),
                                          className: css.editor
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--meta-title",
                                          children: "Meta Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "provider-brochure-form--meta-title",
                                            value: form.input.metaTitle,
                                            placeholder: "Add a meta title.",
                                            onChange: (function ($$event) {
                                                form.updateMetaTitle((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                subtitle: input.subtitle,
                                                                metaTitle: value,
                                                                metaDescription: input.metaDescription,
                                                                active: input.active,
                                                                providerId: input.providerId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaTitleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--meta-description",
                                          children: "Meta Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "provider-brochure-form--meta-description",
                                            value: form.input.metaDescription,
                                            placeholder: "Add a meta description.",
                                            onChange: (function ($$event) {
                                                form.updateMetaDescription((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                subtitle: input.subtitle,
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: value,
                                                                active: input.active,
                                                                providerId: input.providerId
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaDescriptionResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--pdf-file",
                                          children: "PDF File"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(ResourceDocument.make, {
                                            document: state.document,
                                            removeDocument: (function (param) {
                                                dispatch({
                                                      TAG: "SetDocument",
                                                      _0: undefined
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(Uploader.make, {
                                            allowed: ["Pdf"],
                                            multiple: false,
                                            upload: (function (files) {
                                                Belt_Array.map(files, (function (file) {
                                                        var match = Uploader.FileType.fromMime(file.type);
                                                        if (match === "Pdf") {
                                                          return dispatch({
                                                                      TAG: "SetDocument",
                                                                      _0: Caml_option.some(file)
                                                                    });
                                                        }
                                                        
                                                      }));
                                              }),
                                            setUploadHandler: (function (prim) {
                                                
                                              }),
                                            children: match$2 !== undefined ? null : JsxRuntime.jsxs(Button.make, {
                                                    size: "SM",
                                                    color: "Teal",
                                                    buttonClassName: css.uploadDocumentButton,
                                                    children: [
                                                      JsxRuntime.jsx(IconAdditionalUpload.make, {
                                                            size: "MD",
                                                            color: "White"
                                                          }),
                                                      "Upload Document"
                                                    ]
                                                  })
                                          })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--active",
                                          children: "Active"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsxs(Select.make, {
                                          id: "provider-brochure-form--active",
                                          value: form.input.active,
                                          onChange: (function ($$event) {
                                              form.updateActive((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              subtitle: input.subtitle,
                                                              metaTitle: input.metaTitle,
                                                              metaDescription: input.metaDescription,
                                                              active: value,
                                                              providerId: input.providerId
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "false",
                                                  children: "False"
                                                }, "approved-false"),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "true",
                                                  children: "True"
                                                }, "approved-true")
                                          ]
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "provider-brochure-form--provider-brochure-providerId",
                                          children: "Provider"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx(Select.make, {
                                          id: "provider-brochure-form--providerId",
                                          value: ID.toString(form.input.providerId),
                                          onChange: (function ($$event) {
                                              form.updateProviderId((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              subtitle: input.subtitle,
                                                              metaTitle: input.metaTitle,
                                                              metaDescription: input.metaDescription,
                                                              active: input.active,
                                                              providerId: value
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: Belt_Array.map(providers, (function (provider) {
                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                              value: ID.toString(provider.id),
                                                              children: provider.name
                                                            }, "provider-brochure-form--provider-" + ID.toString(provider.id));
                                                }))
                                        })
                                  })
                            ]
                          }),
                      tmp$1,
                      JsxRuntime.jsxs(Row.make, {
                            className: "row",
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonHiddenContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx("div", {})
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCreateContainer,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "Primary",
                                          expanded: true,
                                          disabled: form.submitting,
                                          visuallyDisabled: match$4 === undefined,
                                          busy: form.submitting,
                                          submit: true,
                                          children: "Create Provider Brochure"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCancelContainer,
                                    md: 5,
                                    lg: 5,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "White",
                                          expanded: true,
                                          busy: false,
                                          submit: true,
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Url.visit(Routes_ProviderBrochure.Dashboard.index);
                                            }),
                                          children: "Cancel"
                                        })
                                  })
                            ]
                          })
                    ]
                  }),
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = ProviderBrochureNewForm;

export {
  css ,
  ProviderBrochureForm ,
  make ,
}
/* css Not a pure module */
